import { render, staticRenderFns } from "./ReviewRow.vue?vue&type=template&id=32e6f9ba&scoped=true&lang=pug&"
import script from "./ReviewRow.vue?vue&type=script&lang=ts&"
export * from "./ReviewRow.vue?vue&type=script&lang=ts&"
import style0 from "./ReviewRow.vue?vue&type=style&index=0&id=32e6f9ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e6f9ba",
  null
  
)

export default component.exports