
import { Vue, Component, Prop } from 'vue-property-decorator';
import 'cropperjs/dist/cropper.css';
import ReviewRow from './ReviewRow.vue';
import { Rates } from '@/store/modules';

@Component({
  name: "RecentReviews",
  components: {
    ReviewRow,
  },
})
export default class extends Vue {
  @Prop() classId!: string;
  @Prop() teacherId!: string;
  loadingReviews = false;
  reviews: any[] = [];
  total = 0;

  mounted() {
    this.fetchReviews();
  }

  async fetchReviews() {
    this.loadingReviews = true;
    try {
      const { items, count } = await Rates.listTeacherRates({ id: this.teacherId, limit: 5, offset: this.reviews.length });
      this.total = count;
      this.reviews.push(...items.map((item: any) => { return { ...item, rating: Number(item.rating) } }));
    } catch (err) {
      this.reviews = [];
    }
    this.loadingReviews = false;
  }
}
