
import { Vue, Component, Prop } from 'vue-property-decorator';
import 'cropperjs/dist/cropper.css';

@Component({
  name: "ReviewRow",
})
export default class extends Vue {
  @Prop() review?: any[];

  get className(): string {
    // eslint-disable-next-line
    // @ts-ignore
    return this.review?.class?.name;
  }
}
